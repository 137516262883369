import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import MerchantsApi from '../MerchantsApi';
import Stage from '../../../elements/Stage/Stage';
import MerchantsMidTidForm from '../Components/MerchantsMidTidForm';

const MerchantsStagePosFormReceived: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = MerchantsApi.update(data.id, "stage_pos_form_received");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"pos_form_received"}
            heading={"POS Form Received"}
            editCaps={["stage_pos_form_receivedMerchants"]}

            editable={data.pos_form_received === "yes" && data.status === "new_merchant"}
            showForm={data.pos_form_received !== "yes" && data.status === "new_merchant"}
            showMeta={data.pos_form_received}
            showSave={form.changed}
            passed={data.pos_form_received === "yes"}
            columnA={[
                {
                    key: "pos_form_received",
                    label: "POS Form Received",
                    format: "titleCase"
                }
            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"pos_form_received"}
                                label={"POS Form Received"}
                                defaultValue={form.defaults.pos_form_received}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.pos_form_received === "yes" && (

                            <Col col={{ xs: 24 }}>

                                <MerchantsMidTidForm
                                    form={form}
                                    data={data}
                                />

                            </Col>

                        )}

                    </Row>

                </>

            }
        />

    ), [props, form, process.loading]);

}

export default MerchantsStagePosFormReceived;
