import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import React, { useEffect } from 'react';
import { Col, FieldNumber, Row } from '@dex/bubl-dash';
import { doMath, get } from '@dex/bubl-helpers';

const FieldAmount: React.FC<FieldAmountProps> = (props) => {

    const { sst, enableUnitPrice, quantity, ...rest } = props;

    const unitPrice = get(rest.form.values, rest.name + "_unit");

    useEffect(() => {

        if (!enableUnitPrice) return;

        let subTotal = doMath(quantity);

        subTotal = subTotal.multipliedBy(unitPrice);

        rest.form.handleChange({
            name: rest.name,
            value: subTotal.toFixed(2),
            reset: true,
            silent: true
        });

    }, [quantity, unitPrice]);

    return (

        <Row gutter={8} edge={true}>

            {enableUnitPrice &&
                <>
                    <Col col={{ xs: 9, md: 8 }}>

                        <FieldNumber
                            prefix={"RM "}
                            {...rest}
                            name={rest.name + "_unit"}
                            defaultValue={rest.defaultValueUnit || rest.form.defaults[rest.name + "_unit"]}
                            label='Unit Price'
                        />

                    </Col>

                    <Col col={{ xs: 9, md: 8 }}>

                    <FieldNumber
                        key={rest.defaultValue}
                            prefix={"RM "}
                            {...rest}
                            disabled
                        />

                    </Col>
                </>
            }

            {!enableUnitPrice &&
                <Col col={{ xs: 18, md: 16 }}>

                    <FieldNumber
                        prefix={"RM "}
                        {...rest}
                    />

                </Col>
            }

            {sst &&
                <Col col={{ xs: 6, md: 8 }}>

                    <FieldSelect
                        form={rest.form}
                        label={rest.label ? "SST %" : ""}
                        name={rest.name + "_sst"}
                        defaultValue={rest.defaultValueSst || rest.form.defaults[rest.name + "_sst"]}
                        placeholder={rest.label ? "0%" : "SST %"}
                        isClearable={false}
                        options={[
                            { label: "0%", value: "0%" },
                            { label: "6%", value: "6%" },
                            { label: "8%", value: "8%" },
                        ]}
                    />

                </Col>
            }

        </Row>

    )

}

interface FieldAmountProps {
    [key: string]: any,
}

export default FieldAmount;