import React, { useCallback } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, FieldRadio, FieldRelationship, FieldTextArea, Row, Space } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';
import { FieldHidden } from '@dex/bubl-dash';
import { Alert } from '@dex/bubl-dash';

const ServicesImportValidateForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, services, handleReset, type } = props;

    const columns: any = [
        {
            label: "#",
            key: "index",
        },
        {
            label: "Message",
            key: "message",
        },
        type === "amendment" && {
            label: "Old MA-ID",
            key: "old_ma_id",
        },
        type === "amendment" && {
            label: "New ID",
            key: "new_id",
        },
        type === "annual_visitation" && {
            label: "Queue Next Visit",
            key: "que_next_visit",
            format: "titleCase"
        },
        type === "yearly_rental" && {
            label: "Queue Yearly Rental",
            key: "que_yearly_rental",
            format: "titleCase"
        },
        (type === "yearly_rental" || type === "annual_visitation") && {
            label: "Merchant ID",
            key: "merchant_id",
        },
        (type === "yearly_rental" || type === "annual_visitation") && {
            label: "Company Name",
            key: "company_name",
            elipsis: true,
        },
        (type === "yearly_rental" || type === "annual_visitation") && {
            label: "Trading Name",
            key: "trading_name",
            elipsis: true,
        },
        (type === "yearly_rental" || type === "annual_visitation") && {
            label: "Status",
            key: "status",
            format: "status",
            prefix: "merchants.status"
        },
        (type === "yearly_rental" || type === "annual_visitation") && {
            label: "State",
            key: "state",
        },
        type === "yearly_rental" && {
            label: "Warranty Status",
            key: "warranty_status",
        },
        type === "yearly_rental" && {
            label: "Warranty End Date",
            key: "warranty_end",
        },
    ].filter(Boolean);

    // eslint-disable-next-line
    let data = props.data || {};

    const getClassByStatus = useCallback((row) => {

        return row.valid ? "" : "table-bg-yellow";

    }, []);

    return (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <FieldHidden
                form={form}
                name={'type'}
                value={type}
            />

            <FieldHidden
                form={form}
                name={'services'}
                value={services.list}
            />

            {services.success > 0 && type === "annual_visitation" &&
                <>
                    <Alert
                        type={"info"}
                        message={services.success + " Annual Visitations Found."}
                        description={"Select service provider & click submit below to generate annual visitation."}
                    />
                    <Space />
                </>
            }

            {services.success > 0 && type === "yearly_rental" &&
                <>
                    <Alert
                        type={"info"}
                        message={services.success + " Yearly Rentals Found."}
                        description={"Select service provider & click submit below to generate yearly rental."}
                    />
                    <Space />
                </>
            }

            {services.success > 0 && type === "amendment" &&
                <>
                    <Alert
                        type={"info"}
                        message={services.success + " Amendments Found."}
                        description={"Click submit below to generate amendments."}
                    />
                    <Space />
                </>
            }

            {services.error > 0 &&
                <>
                    <Alert
                        type={"warning"}
                        description={services.error + " Errors Occurred. Please check the list below."}
                    />
                    <Space />
                </>
            }

            {(type === "annual_visitation") &&
                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRelationship
                            form={form}
                            label={"Service Provider"}
                            name='provider_id'
                            required={true}
                            model={'Providers'}
                            preload={true}
                            defaultValue={form.defaults.provider_id}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRadio
                            form={form}
                            label={"Service Request Accepted"}
                            name='request_accepted'
                            required={false}
                            defaultValue={form.defaults.request_accepted}
                            options={[
                                { value: "yes", label: "Yes" },
                            ]}
                        />
                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRadio
                            form={form}
                            label={"Cancel Existing Visits"}
                            name='cancel_visits'
                            required={false}
                            defaultValue={form.defaults.cancel_visits}
                            options={[
                                { value: "yes", label: "Yes" },
                                { value: "no", label: "No" },
                            ]}
                        />
                    </Col>

                </Row>
            }

            <Space />

            <ButtonRow>
                <Button
                    label={"Generate Service Requests"}
                    loading={process.loading}
                    disabled={services.error && form.values.cancel_visits !== "yes"}
                />

                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={handleReset}
                />

            </ButtonRow>

            <Space />

            <DataTable
                data={services.list}
                columns={columns}
                rowClassName={getClassByStatus}
            />

        </Form>

    );
}

export default ServicesImportValidateForm;
