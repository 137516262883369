import React, { useMemo, useState } from 'react';
import { Panel, Form, Row, Col, Line, ButtonRow, Button, FieldDate, FieldRelationship, FieldTextArea, FieldList, FieldText, FieldHidden, WhiteSpace, Modal, FieldRadio } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import TerminalsImportForm from '../../Terminals/Components/TerminalsImportForm';

const InventoriesBatchTransferForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [showImport, setShowImport] = useState("");

    return useMemo(() => (


        <Panel
            heading={"Batch Transfer"}
        >

            {showImport &&
                <Modal
                    show={true}
                    onClose={setShowImport.bind(null, "")}
                    width={620}
                >

                    <TerminalsImportForm
                        form={form}
                        type={showImport}
                        handleClose={setShowImport.bind(null, "")}
                    />

                </Modal>
            }

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldRadio
                            label='Transfer To'
                            form={form}
                            name='transfer_to'
                            required={true}
                            defaultValue={form.defaults.transfer_to}
                            options={[
                                { label: "Perso", value: "perso" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Seperator
                            heading={<>
                                Swivel
                                <WhiteSpace />
                                <Button
                                    type={"light"}
                                    label={"Import"}
                                    size={"small"}
                                    onClick={setShowImport.bind(null, "swivel")}
                                />
                            </>}
                            bottom={"small"}
                        />

                        <FieldList
                            form={form}
                            name={'swivel'}
                            addButtonLabel={"Add Swivel"}
                        >

                            {Array.isArray(form.values.swivel) && form.values.swivel.map((row, n) => (

                                <div key={form.key + "-" + n}>

                                    <FieldRelationship
                                        form={form}
                                        name={`swivel[${n}]`}
                                        required={false}
                                        model={'Inventories'}
                                        preload={true}
                                        where={{ status: "in_stock", type: "swivel", in_stock: { inq: ["store", "store_used"] } }}
                                        defaultValue={row}
                                    />

                                </div>

                            ))}

                        </FieldList>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Seperator
                            heading={<>
                                Router
                                <WhiteSpace />
                                <Button
                                    type={"light"}
                                    label={"Import"}
                                    size={"small"}
                                    onClick={setShowImport.bind(null, "router")}
                                />
                            </>}
                            bottom={"small"}
                        />

                        <FieldList
                            form={form}
                            name={'router'}
                            addButtonLabel={"Add Router"}
                        >

                            {Array.isArray(form.values.router) && form.values.router.map((row, n) => (

                                <div key={form.key + "-" + n}>

                                    <FieldRelationship
                                        form={form}
                                        name={`router[${n}]`}
                                        required={false}
                                        model={'Inventories'}
                                        preload={true}
                                        where={{ status: "in_stock", type: "router", in_stock: { inq: ["store", "store_used"] } }}
                                        defaultValue={row}
                                    />

                                </div>

                            ))}

                        </FieldList>

                    </Col>


                    <Col col={{ xs: 24, md: 12 }}>

                        <Seperator
                            heading={<>
                                Sim Cards
                                <WhiteSpace />
                                <Button
                                    type={"light"}
                                    label={"Import"}
                                    size={"small"}
                                    onClick={setShowImport.bind(null, "sim")}
                                />
                            </>}
                            bottom={"small"}
                        />

                        <FieldList
                            form={form}
                            name={'sim'}
                            addButtonLabel={"Add Sim Card"}
                        >

                            {Array.isArray(form.values.sim) && form.values.sim.map((row, n) => (

                                <div key={form.key + "-" + n}>

                                    <FieldRelationship
                                        form={form}
                                        name={`sim[${n}]`}
                                        required={false}
                                        model={'Inventories'}
                                        preload={true}
                                        where={{ status: "in_stock", type: "sim", in_stock: { inq: ["store", "store_used"] } }}
                                        defaultValue={row}
                                    />

                                </div>

                            ))}

                        </FieldList>

                    </Col>


                    <Col col={{ xs: 24, md: 12 }}>

                        <Seperator
                            heading={<>
                                Dockings
                                <WhiteSpace />
                                <Button
                                    type={"light"}
                                    label={"Import"}
                                    size={"small"}
                                    onClick={setShowImport.bind(null, "docking")}
                                />
                            </>}
                            bottom={"small"}
                        />


                        <FieldList
                            form={form}
                            name={'docking'}
                            addButtonLabel={"Add Docking"}
                        >

                            {Array.isArray(form.values.docking) && form.values.docking.map((row, n) => (

                                <div key={form.key + "-" + n}>

                                    <FieldRelationship
                                        form={form}
                                        name={`docking[${n}]`}
                                        required={false}
                                        model={'Inventories'}
                                        preload={true}
                                        where={{ status: "in_stock", type: "docking", in_stock: { inq: ["store", "store_used"] } }}
                                        defaultValue={row}
                                    />

                                </div>

                            ))}

                        </FieldList>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [showImport, props]);

}

export default InventoriesBatchTransferForm;
