import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import MerchantsApi from './MerchantsApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';
import { wpOldLink } from '../../functions/wpOldLink';
import OriginalModelLink from '../../elements/OriginalModelLink/OriginalModelLink';

let settings;

export default settings = {
    key: 'merchants',
    zone: 'merchants',
    path: '/merchants',
    title: "Merchants",
    endpoint: MerchantsApi.endpoint,
    modelName: 'Merchants',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewMerchants"],
    viewCaps: ["viewMerchants"],
    createCaps: ["createMerchants"],
    actions: ['trash', 'clone'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "ESA",
                path: settings.path + "/model/esa",
                caps: settings.indexCaps
            },
            {
                label: "Rent To Own",
                path: settings.path + "/model/rent",
                caps: settings.createCaps
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
                caps: settings.indexCaps
            },
            {
                label: "New",
                path: settings.path + "/status/new_merchant",
                caps: settings.indexCaps
            },
            {
                label: "Installation",
                path: settings.path + "/status/installation",
                caps: settings.indexCaps
            },
            {
                label: "Floating",
                path: settings.path + "/status/floating",
                caps: settings.createCaps
            },
            {
                label: "Inactive",
                path: settings.path + "/status/inactive",
                caps: settings.indexCaps
            },
            {
                label: "Duplicate",
                path: settings.path + "/status/duplicate",
                caps: settings.indexCaps
            },
            {
                label: "Cancelled",
                path: settings.path + "/status/cancelled",
                caps: settings.indexCaps
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: MerchantsApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {

            },
            include: [
                {
                    relation: 'engagement_plan',
                    preset: "link"
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'agent',
                    preset: "link"
                },
                {
                    relation: 'swivel',
                    preset: "link"
                },
                {
                    relation: 'router',
                    preset: "link"
                },
                {
                    relation: 'docking',
                    preset: "link"
                },
                {
                    relation: 'sim',
                    preset: "link"
                },
            ],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        let { model, status } = params || {};

        if (status) filters.where.status = { inq: [status] };

        if (model === "rent") filters.where.business_model = { inq: ["rent-to-own", 'rental', "esa-rent-to-own"] };
        else if (model) filters.where.business_model = model;

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        let formats: any = [];

        if (!user || !user.caps.includes("exportMerchants")) return formats;

        if (user.caps.includes("staff")) {

            formats = [
                { value: "merchants", label: "Merchants Complete" },
                { value: "merchantDetails", label: "Merchant Details" },
                { value: "merchantAnnualVisitation", label: "Merchant Annual Visitation" },
                { value: "merchantYearlyRental", label: "Merchant Yearly Rental" },
                { value: "merchantBusinessInfo", label: "Merchant Business Info" },
                { value: "merchantsMid", label: "Merchant MID" },
                { value: "merchantsStatus", label: "Merchant Status" },
            ];

        } else {

            formats = [
                { value: "merchantDetails", label: "Merchant Details" }
            ];

        }

        return formats;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'original_id', 'company_name', 'trading_name', 'mid', 'tid', 'serial'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Merchant ID",
                key: "id",
                type: "text"
            },
            {
                label: "Company Name",
                key: "company_name",
                type: "text"
            },
            {
                label: "Trading Name",
                key: "trading_name",
                type: "text"
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "new_merchant", label: "New Merchant" },
                    { value: "active", label: "Active" },
                    { value: "installation", label: "Installation" },
                    { value: "floating", label: "Floating" },
                    { value: "inactive", label: "Inactive" },
                    { value: "duplicate", label: "Duplicate" },
                    { value: "cancelled", label: "Cancelled" },
                ]
            },
            {
                label: "Retrieval Status",
                key: "retrieval",
                type: "choice",
                options: [
                    { value: "pending", label: "Pending" },
                    { value: "collected", label: "Collected" },
                    { value: "failed", label: "Failed" },
                    { value: "cancelled", label: "Cancelled" },
                ]
            },
            {
                label: "Acquirer",
                key: "acquirer_id",
                type: "relationship",
                model: "Acquirers"
            },
            {
                label: "Device Type",
                key: "device_type",
                type: "text"
            },
            {
                label: "Engagement Plan",
                key: "engagement_plan_id",
                type: "relationship",
                model: "EngagementPlans"
            },
            {
                label: "Business Model",
                key: "business_model",
                type: "choice",
                options: [
                    { label: "ESA", value: "esa" },
                    { label: "Rent To Own", value: "rent-to-own" },
                    { label: "Rental", value: "rental" },
                    { label: "ESA / Rent To Own", value: "esa-rent-to-own" },
                    { label: "ESA / Rental", value: "esa-rental" },
                    { label: "Annual Rental", value: "maintenance" }
                ]
            },
            {
                label: "Action Type",
                key: "action_type",
                type: "choice",
                options: [
                    { value: 'New Setup', label: 'New Setup' },
                    { value: 'Swapping', label: 'Swapping' },
                    { value: 'In-House Conversion', label: 'In-House Conversion' },
                    { value: 'Temporary', label: 'Temporary' },
                    { value: 'Change Management', label: 'Change Management' },
                    { value: 'Upgrade (No Commission)', label: 'Upgrade (No Commission)' },
                    { value: 'Swapping (No Commission)', label: 'Swapping (No Commission)' },
                ]
            },
            {
                label: "MID",
                key: "mid",
                type: "text"
            },
            {
                label: "TID",
                key: "tid",
                type: "text"
            },
            {
                label: "Active Date",
                key: "active_date",
                type: "date"
            },
            {
                label: "Retrieved Date",
                key: "retrieved_date",
                type: "date"
            },
            {
                label: "Floating Date",
                key: "floating_date",
                type: "date"
            },
            {
                label: "Inactive Date",
                key: "inactive_date",
                type: "date"
            },
            {
                label: "Re-Activated Date",
                key: "reused_date",
                type: "date"
            },
            {
                label: "Annual Visit Date",
                key: "visit_date",
                type: "date"
            },
            {
                label: "Warranty End Date",
                key: "warranty_end",
                type: "date"
            },
            {
                label: "State",
                key: "installation_address.state",
                type: "choice",
                options: [
                    { label: "JOHOR", value: "JOHOR" },
                    { label: "KEDAH", value: "KEDAH" },
                    { label: "KELANTAN", value: "KELANTAN" },
                    { label: "KUALA LUMPUR", value: "KUALA LUMPUR" },
                    { label: "LABUAN", value: "LABUAN" },
                    { label: "MALACCA", value: "MALACCA" },
                    { label: "NG. SEMBILAN", value: "NG. SEMBILAN" },
                    { label: "PAHANG", value: "PAHANG" },
                    { label: "PERAK", value: "PERAK" },
                    { label: "PERLIS", value: "PERLIS" },
                    { label: "PENANG", value: "PENANG" },
                    { label: "PUTRAJAYA", value: "PUTRAJAYA" },
                    { label: "SABAH", value: "SABAH" },
                    { label: "SARAWAK", value: "SARAWAK" },
                    { label: "SELANGOR", value: "SELANGOR" },
                    { label: "TERENGGANU", value: "TERENGGANU" },
                ]
            },
            {
                label: "Serial Number",
                key: "serial",
                type: "text"
            },
            {
                label: "Power Adapter Type",
                key: "adapter_type",
                type: "choice",
                options: [
                    { label: "Original", value: "original" },
                    { label: "Third Party", value: "third_party" },
                ]
            },
            {
                label: "Sim Card No",
                key: "simcard_no",
                type: "text"
            },
            {
                label: "Contact Person",
                key: "person",
                type: "text"
            },
            {
                label: "Phone",
                key: "phone",
                type: "text"
            },
            {
                label: "Email",
                key: "email",
                type: "text"
            },
            {
                label: "Agent",
                key: "agent_id",
                type: "relationship",
                model: "Users"
            },
            {
                label: "Reference ID",
                key: "reference_id",
                type: "text"
            },
            {
                label: "Tags",
                key: "tags",
                type: "text"
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },

        ].filter(Boolean);

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Merchant ID",
                key: "this",
                format: "ModelLink",
                sort: 'id',
                render: (value, row) => <OriginalModelLink data={row} />
            },
            {
                label: "Company Name",
                key: "company_name",
                elipsis: true,
            },
            {
                label: "Trading Name",
                key: "trading_name",
                elipsis: true,
            },
            {
                label: "Status",
                key: "status",
                format: "status",
                prefix: "merchants.status",
                render: (value, row) => (
                    <>
                        <Status status={row.status} prefix='merchants.status' />
                        {row.retrieval && <><br /><Status status={row.retrieval} prefix='merchants.retrieval' /></>}
                    </>
                )
            },
            params.status === "new_merchant" && {
                label: "Documents",
                key: "documents_verified_status",
                format: "status",
                prefix: "merchants.verified",
                render: (value, row) => (
                    <>
                        <Status status={row.documents_verified_status} prefix='merchants.verified' />
                    </>
                )
            },
            params.status === "new_merchant" && {
                label: "Approval",
                key: "documents_submitted_status",
                format: "status",
                prefix: "merchants.submitted",
                render: (value, row) => (
                    <>
                        <Status status={row.documents_submitted_status} prefix='merchants.submitted' />
                    </>
                )
            },
            {
                label: "Acquirer",
                key: "acquirer",
                format: "ModelLink"
            },
            {
                label: "Device Type",
                key: "device_type",
                format: "intl",
                prefix: "terminals.type"
            },
            {
                label: "Engagement Plan",
                key: "engagement_plan",
                format: "ModelLink"
            },
            {
                label: "Business Model",
                key: "business_model",
                format: "status",
                prefix: "engagement_plans.business_model"
            },
            {
                label: "Action Type",
                key: "action_type",
            },
            {
                label: "MID",
                key: "mid",
            },
            {
                label: "TID",
                key: "tid",
            },
            {
                label: "Installation Date",
                key: "installation_date",
                format: "dateDay"
            },
            (params.model === "rent" || params.model === "floating") ? {
                label: "Retrieved Date",
                key: "retrieved_date",
                format: "dateDay"
            } : null,
            (params.status === "inactive") ? {
                label: "Inactive Date",
                key: "inactive_date",
                format: "dateDay",
            } : null,
            {
                label: "Floating Date",
                key: "floating_date",
                format: "dateDay",
            },
            {
                label: "Annual Visit Date",
                key: "visit_date",
                format: "dateDay",
            },
            {
                label: "Warranty End Date",
                key: "warranty_end",
                format: "dateDay",
            },
            {
                label: "State",
                key: "installation_address.state",
            },
            {
                label: "Serial Number",
                key: "serial",
            },
            {
                label: "Power Adapter Type",
                key: "adapter_type",
                format: "intl",
                prefix: "installation.adapter_type",
            },
            {
                label: "Sim Card No",
                key: "sim_card_no",
            },
            {
                label: "Contact Person",
                key: "person",
            },
            {
                label: "Phone",
                key: "phone",
            },
            {
                label: "Email",
                key: "email",
            },
            {
                label: "Advisor",
                key: "agent",
                format: "ModelLink"
            },
            {
                label: "Reference ID",
                key: "reference_id",
            },
            {
                label: "Additional Tags",
                key: "tags",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: MerchantsApi,
                settings: settings,
            }
        ].filter(Boolean);

        return columns;

    },
    singleFetch: MerchantsApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                'engagement_plan',
                {
                    relation: 'terminal',
                    preset: "link"
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'agent',
                    preset: "link"
                },
                {
                    relation: 'agency',
                    preset: "link"
                },
                {
                    relation: 'installation',
                    preset: "link"
                },
                {
                    relation: 'invoice',
                    preset: "link"
                },
                {
                    relation: 'proforma_invoice',
                    preset: "link"
                },
                {
                    relation: 'swivel',
                    preset: "link"
                },
                {
                    relation: 'router',
                    preset: "link"
                },
                {
                    relation: 'docking',
                    preset: "link"
                },
                {
                    relation: 'sim',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>
            {title}
            <WhiteSpace />
            {data &&
                <Status status={data?.status} prefix='merchants.status' />
            }
            {data && data.installation && data.installation.status === "failed" && <>
                <WhiteSpace />
                <Status status={data.installation.status} prefix='installation.status' /></>
            }
            {data && data.retrieval && <>
                <WhiteSpace />
                <Status status={data.retrieval} prefix='merchants.retrieval' /></>
            }
        </>;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        const data = fetch?.data || {};

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps,
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps,
            },
            (["new_merchant", "installation"].includes(data.status)) && {
                label: "Cancel",
                path: path + "/cancel",
                caps: settings.createCaps,
            },
            {
                label: "Activity",
                path: path + "/activity",
                caps: settings.viewCaps
            },
        ].filter(Boolean)

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
            {
                label: "Copy to New",
                path: settings.path + "/create?cloneId=" + params.id,
                caps: settings.createCaps,
                type: "light"
            },
        ].filter(Boolean);

    },
};