import React, { useCallback, useEffect, useState } from 'react';
import { Button, FieldTextArea, Form, Line, Panel, useForm } from '@dex/bubl-dash';
import TerminalsApi from '../TerminalsApi';
import { clone } from '@dex/bubl-helpers';

const TerminalsImportForm: React.FC<any> = (props: any) => {

    const { type, form, handleClose } = props;

    const [loading, setLoading] = useState(false);

    const importForm = useForm({});

    const options = {
        timeout: 60000,
    };

    const check = TerminalsApi.create(options, "check_serials");

    const handleImport = useCallback((values) => {

        const serials = values.serials = values.serials.split("\n").map((serial: string) => { return serial.trim() }).filter(Boolean);

        let name = type;

        if (type === "terminals") name = "terminal_ids";

        check.run({
            data: { type: type, serials: serials },
            onComplete: (response) => {
                setValues(name, response);
            },
        })

    }, []);

    useEffect(() => {

        return () => {
            check.cancel();
        }

    }, [check.hash]);

    const setValues = (name, values) => {

        setLoading(true);

        setTimeout(() => {

            let value = clone(form.values[name] || []);

            value = [...value, ...values];

            form.handleChange({ name: name, value: value, reset: true });

            setTimeout(() => {

                setLoading(false);

                handleClose();

            }, 100);

        }, 100)

    }

    return (

        <Panel
            heading={"Import (Store - New | Store - Used)"}
        >

            <Form
                form={importForm}
                onSubmit={handleImport}
                loading={loading || check.loading}
            >

                <FieldTextArea
                    form={importForm}
                    name={"serials"}
                    defaultValue={""}
                    rows={10}
                    placeholder={"Enter serials here, one per line"}
                />

                <Line />

                <Button
                    label={"Import"}
                    loading={loading || check.loading}
                />

            </Form>

        </Panel>

    );

}

export default TerminalsImportForm;
