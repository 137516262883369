import { dateDay } from "@dex/bubl-helpers";
import moment from "moment";

const today = moment().endOf("day");
const twoMonths = moment().endOf("day").subtract(2, "months");

export const validateDatePassed = (date, isTechnician) => {

    if (today.isBefore(date)) return true;

    if (isTechnician && twoMonths.isAfter(date)) return true;

    return false;

};

export const disablePastDates = (date) => {
    const today = moment();
    const twoMonthsAhead = today.clone().add(2, "months");

    if (moment(date).isBefore(today, "day") || moment(date).isAfter(twoMonthsAhead, "day")) {
        return true;
    }

    return false;
};


export const invoiceHasSst = (date_issued): boolean => {

    const cutOff = moment(dateDay("2024-10-31"));
    const dateIssued = moment(date_issued);

    if (dateIssued.isAfter(cutOff)) {
        return true;
    }
    return false;

}