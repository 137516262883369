import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import MerchantsApi from '../MerchantsApi';
import Stage from '../../../elements/Stage/Stage';
import { FieldCheckbox, FieldTextArea, useApp } from '@dex/bubl-dash';
import { loop } from '@dex/bubl-helpers';

const MerchantsStageVerifyDocuments: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = MerchantsApi.update(data.id, "stage_documents_verified");

    const app = useApp();

    const [settings] = app.store("appSettings");

    const checklists = (settings.checklists?.document || []).map(item => { return { label: item, value: item } });

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"documents_verified"}
            heading={"Verify Documents"}
            editCaps={["stage_documents_verifiedMerchants"]}
            editable={data.documents_verified === "yes" && data.status === "new_merchant"}
            showForm={data.documents_verified !== "yes" && data.status === "new_merchant"}
            showMeta={data.documents_verified}
            showSave={form.changed}
            passed={data.documents_verified === "yes"}
            columnA={[
                {
                    key: "documents_verified",
                    label: "Documents Verified",
                    format: "titleCase"
                },
                {
                    key: "documents_verified_status",
                    label: "Status",
                    format: "status",
                    prefix: "merchants.verified",
                }
            ]}
            columnB={[
                {
                    key: "documents_verified_checklist",
                    label: "Documents Checklist",
                },
                {
                    key: "documents_verified_remarks",
                    label: "Remarks",
                    format: "nl2br"
                }
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                required={true}
                                name={"documents_verified"}
                                label={"Documents Verified"}
                                defaultValue={form.defaults.documents_verified}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldCheckbox
                                form={form}
                                required={false}
                                name={"documents_verified_checklist"}
                                label={"Documents Checklist"}
                                defaultValue={form.defaults.documents_verified_checklist}
                                options={checklists}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>


                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldTextArea
                                form={form}
                                name={"documents_verified_remarks"}
                                required={false}
                                label={"Remarks"}
                                defaultValue={form.defaults.documents_verified_remarks}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form, process.loading]);

}

export default MerchantsStageVerifyDocuments;
