import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import InventoriesCreateEditForm from "./Components/InventoriesCreateEditForm";
import InventoriesApi from "./InventoriesApi";
import { get } from '@dex/bubl-helpers';
import InventoriesBatchTransferForm from './Components/InventoriesBatchTransferForm';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: InventoriesCreateEditForm,
                getCreate: InventoriesApi.create,
                getClone: InventoriesApi.getOne,
                defaults: {
                    type: "desktop",
                    has_serial: "yes"
                },
                successCallback: (response, app, nav) => {
                    const route = { "type": "Navigation/NAVIGATE", routeName: settings.key, params: { batch: response.batch } };
                    app.navigate(nav, 'push', route);
                },
                idKey: settings.idKey,
            },
        },
    });

    app.addRoute({
        name: settings.key + "BatchTransfer",
        zone: settings.zone,
        path: settings.key + "/transfer",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Batch Transfer",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: InventoriesBatchTransferForm,
                getCreate: InventoriesApi.create,
                getClone: InventoriesApi.getOne,
                handler: "batch_transfer",
                handlerOptions: {
                    timeout: 360000
                },
                defaults: {},
                successCallback: (response, app, nav) => {
                    const type = get(nav, 'currentRoute.state.params.type', '');
                    const route = {
                        "type": "Navigation/NAVIGATE",
                        routeName: settings.key,
                        params: {
                            type: type,
                            batch: response.batch
                        }
                    };
                    app.navigate(nav, 'push', route);
                },
                idKey: settings.idKey,
            },
        },
    });

}