import React, { useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import InventoriesSettings from '../../Inventories/InventoriesSettings';

const MassRolloutsDocking: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        const dockings = data.docking.map(docking => docking);

        let filters = InventoriesSettings.indexFetchParams(routeName, params, users);

        filters.where.id = { inq: dockings };

        return filters;

    };

    return useMemo(() => (

        <>

            <IndexTable
                title={"Docking"}
                getFetch={InventoriesSettings.indexFetch}
                getColumns={InventoriesSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                getQuickSearch={InventoriesSettings.indexQuickSearch}
                getSearchFields={InventoriesSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data, tableState]);

}

export default MassRolloutsDocking;
