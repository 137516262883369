import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import TerminalsApi from '../TerminalsApi';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { Panel, FieldText, FieldSelect, FieldRelationship, Line, ButtonRow, Button, useMountEffect, userHasRole } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';


const TerminalsSingleSingleTransfer: React.FC<any> = (props: any) => {

    const app = useApp();
    const [nav] = app.store('nav');

    const { data } = props;

    const form = useForm(data);
    const isManager = userHasRole("manager") || userHasRole("super");

    const transfer_status = ["deployed", 'in_process', "delivered", "write_off"].includes(data.status);

    const update = TerminalsApi.update(data.id, "transfer", {
        onComplete: (data) => {

            app.alert('Terminal Transferred', 'success');

            app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    const faultOptions = [
        { value: "Keypad", label: "Keypad" },
        { value: "Thermal Printer", label: "Thermal Printer" },
        { value: "Motherboard", label: "Motherboard" },
        { value: "Daughterboard", label: "Daughterboard" },
        { value: "Magnetic Reader", label: "Magnetic Reader" },
        { value: "LCD Display", label: "LCD Display" },
        { value: "Casing", label: "Casing" },
        { value: "Power Adapter", label: "Power Adapter" },
    ];

    useMountEffect(() => {

        if (data.in_stock === "bench_repair") {

            form.handleChange({ name: "parts_faulty", value: faultOptions.map(item => item.value), reset: true, silent: true });
            form.handleChange({ name: "parts_recovered", value: faultOptions.map(item => item.value), reset: true, silent: true });

        }

    })

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Terminal Single Transfer"}
                >

                    <Form
                        form={form}
                        onSubmit={handleSubmit}
                        loading={update.loading}
                    >

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 16 }}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Serial Number"}
                                            name='serial'
                                            defaultValue={form.defaults.serial}
                                            disabled
                                        />

                                    </Col>

                                    {(transfer_status) && isManager &&
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldRadio
                                                label='Reset To (Store - Used)'
                                                form={form}
                                                name='transfer_store'
                                                required={true}
                                                defaultValue={form.defaults.transfer_store}
                                                options={[
                                                    { value: "yes", label: "Yes" },
                                                    { value: "no", label: "No" },
                                                ]}
                                            />

                                        </Col>
                                    }

                                    {data.status === "in_stock" &&
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldSelect
                                                form={form}
                                                disabled={true}
                                                label={"Current In Stock Location"}
                                                name='in_stock'
                                                required={false}
                                                defaultValue={form.defaults.in_stock}
                                                options={[
                                                    { label: "Store - New", value: "store" },
                                                    { label: "Store - Used", value: "store_used" },
                                                    data.delivery_date ? { label: "Delivered", value: "delivered" } : null,
                                                    { label: "Spare Stock", value: "spare_stock" },
                                                    { label: "Technician", value: "technician" },
                                                    { label: "Outbound Returned", value: "outbound_returned" },
                                                    { label: "Bench Repair", value: "bench_repair" },
                                                    { label: "Warehouse Entry", value: "warehouse_entry" },
                                                    { label: "Acquirer Floating Unit", value: "acquirer_floating_unit" },
                                                    { label: "Write-Off", value: "write_off" }
                                                ].filter(Boolean)}
                                            />

                                        </Col>
                                    }


                                    {data.status === "in_stock" && data.in_stock == "warehouse_entry" &&
                                        <>
                                            {data.application_reset === "yes" && (
                                                <Col col={{ xs: 24, md: 14 }}>

                                                    <FieldRadio
                                                        label='Transfer To'
                                                        form={form}
                                                        name='transfer_to'
                                                        required={true}
                                                        defaultValue={form.defaults.transfer_to}
                                                        options={[
                                                            { value: "spare_stock", label: "Spare Stock" },
                                                            data.delivery_date ? { label: "Delivered", value: "delivered" } : null,
                                                        ].filter(Boolean)}
                                                    />

                                                </Col>
                                            )}

                                            {data.application_reset === "no" && (
                                                <Col col={{ xs: 24, md: 14 }}>

                                                    <FieldRadio
                                                        label='Transfer To'
                                                        form={form}
                                                        name='transfer_to'
                                                        required={true}
                                                        defaultValue={form.defaults.transfer_to}
                                                        options={[
                                                            data.delivery_date ? null : { label: "Store - Used", value: "store_used" },
                                                            data.delivery_date ? { label: "Delivered", value: "delivered" } : null,
                                                        ].filter(Boolean)}
                                                    />

                                                </Col>
                                            )}
                                        </>
                                    }



                                    {data.status === "in_stock" && data.in_stock == "bench_repair" &&
                                        <>

                                            <Col col={{ xs: 24, md: 14 }}>

                                                <FieldRadio
                                                    label='Transfer To'
                                                    form={form}
                                                    name='transfer_to'
                                                    required={true}
                                                    defaultValue={form.defaults.transfer_to}
                                                    options={[
                                                        { value: "warehouse_entry", label: "Warehouse Entry" },
                                                        { value: "write_off", label: "Write-Off" }
                                                    ]}
                                                />

                                            </Col>

                                            {form.values.transfer_to === "warehouse_entry" && (
                                                <Col col={{ xs: 24, md: 14 }}>

                                                    <FieldRadio
                                                        form={form}
                                                        label={"Application Reset"}
                                                        name='application_reset'
                                                        required={true}
                                                        defaultValue={form.defaults.application_reset}
                                                        options={[
                                                            { label: "Yes", value: "yes" },
                                                            { label: "No", value: "no" },
                                                        ]}
                                                    />

                                                </Col>
                                            )}

                                            {form.values.transfer_to === "write_off" && (
                                                <>
                                                    <Col col={{ xs: 24, md: 24 }}>

                                                        <FieldTextArea
                                                            form={form}
                                                            label={"Write-Off Notes"}
                                                            name='write_off_notes'
                                                            required={false}
                                                            defaultValue={form.defaults.write_off_notes}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldCheckbox
                                                            form={form}
                                                            label={"Faults"}
                                                            name='parts_faulty'
                                                            required={false}
                                                            list
                                                            defaultValue={form.defaults.parts_faulty}
                                                            options={faultOptions}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldCheckbox
                                                            form={form}
                                                            label={"Parts Recovered"}
                                                            name='parts_recovered'
                                                            required={false}
                                                            list
                                                            defaultValue={form.defaults.parts_recovered}
                                                            options={faultOptions}
                                                        />

                                                    </Col>



                                                </>

                                            )}

                                        </>
                                    }

                                    {data.status === "in_stock" &&

                                        <>
                                            {data.in_stock != "bench_repair" && data.in_stock != "warehouse_entry" &&
                                                <Col col={{ xs: 24, md: 12 }}>

                                                    <FieldSelect
                                                        form={form}
                                                        label={"Transfer To"}
                                                        name='in_stock'
                                                        required={true}
                                                        defaultValue={form.defaults.in_stock}
                                                        options={[
                                                            // { label: "Store - New", value: "store" },
                                                            { label: "Store - Used", value: "store_used" },
                                                            { label: "Perso", value: "perso" },
                                                            data.delivery_date ? { label: "Delivered", value: "delivered" } : null,
                                                            { label: "Spare Stock", value: "spare_stock" },
                                                            { label: "Technician", value: "technician" },
                                                            { label: "Outbound Returned", value: "outbound_returned" },
                                                            { label: "Bench Repair", value: "bench_repair" },
                                                            { label: "Acquirer Floating Unit", value: "acquirer_floating_unit" },
                                                            { label: "Loan", value: "loan" }
                                                        ].filter(Boolean)}
                                                    />

                                                </Col>
                                            }
                                            {form.values.in_stock === "technician" && (

                                                <>

                                                    <Col col={{ xs: 24 }}>
                                                        <Line top={"small"} bottom={"small"} />
                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldRelationship
                                                            form={form}
                                                            label={"Service Provider"}
                                                            name='provider_id'
                                                            required={true}
                                                            model={'Providers'}
                                                            preload={true}
                                                            defaultValue={form.defaults.provider_id}
                                                        />

                                                    </Col>

                                                    {form.values.provider_id && (

                                                        <Col col={{ xs: 24, md: 12 }}>

                                                            <FieldRelationship
                                                                form={form}
                                                                label={"Technician"}
                                                                name='technician_id'
                                                                required={true}
                                                                model={'Users'}
                                                                preload={true}
                                                                where={{ roles: "technician", provider_id: form.values.provider_id }}
                                                                defaultValue={form.defaults.technician_id}
                                                            />

                                                        </Col>

                                                    )}

                                                </>

                                            )}

                                            {form.values.in_stock === "loan" && (

                                                <>

                                                    <Col col={{ xs: 24 }}>
                                                        <Line top={"small"} bottom={"small"} />
                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Loan To"}
                                                            name='loan_to'
                                                            required={true}
                                                            defaultValue={form.defaults.loan_to}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Loan Purpose"}
                                                            name='loan_purpose'
                                                            required={true}
                                                            defaultValue={form.defaults.loan_purpose}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24 }}>

                                                        <FieldTextArea
                                                            form={form}
                                                            label={"Device Notes"}
                                                            name='device_notes'
                                                            required={false}
                                                            defaultValue={form.defaults.device_notes}
                                                        />

                                                    </Col>


                                                </>

                                            )}

                                        </>

                                    }

                                </Row>

                            </Col>

                        </Row>

                        <Line />

                        <ButtonRow>
                            <Button
                                label={"Save"}
                                loading={update.loading}
                            />
                            <Button
                                label={"Reset"}
                                type={"faded"}
                                onClick={form.reset}
                            />
                        </ButtonRow>

                    </Form>

                </Panel>

            </Col >

        </Row >

    ), [data, form.hash, update]);

}

export default TerminalsSingleSingleTransfer;
