import React, { useMemo, useState } from 'react';
import { Panel, Form, Row, Col, Line, ButtonRow, Button, FieldDate, FieldRelationship, FieldTextArea, FieldList, FieldText, FieldHidden, WhiteSpace, Modal, FieldRadio } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import TerminalsImportForm from './TerminalsImportForm';

const TerminalsBatchTransferForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [showImport, setShowImport] = useState("");

    return useMemo(() => (


        <Panel
            heading={"Batch Transfer"}
        >

            {showImport &&
                <Modal
                    show={true}
                    onClose={setShowImport.bind(null, "")}
                    width={620}
                >

                    <TerminalsImportForm
                        form={form}
                        type={showImport}
                        handleClose={setShowImport.bind(null, "")}
                    />

                </Modal>
            }

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldRadio
                            label='Transfer To'
                            form={form}
                            name='transfer_to'
                            required={true}
                            defaultValue={form.defaults.transfer_to}
                            options={[
                                { label: "Perso", value: "perso" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <Seperator
                            heading={<>
                                Terminals
                                <WhiteSpace />
                                <Button
                                    type={"light"}
                                    label={"Import"}
                                    size={"small"}
                                    onClick={setShowImport.bind(null, "terminals")}
                                />
                            </>}
                            bottom={"small"}
                        />

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldList
                                form={form}
                                name={'terminal_ids'}
                                required={false}
                                addButtonLabel={"Add Terminal"}
                            >

                                {(form.values.terminal_ids || []).map((row, n) => (

                                    <div key={form.key + "-" + n}>

                                        <FieldRelationship
                                            form={form}
                                            name={`terminal_ids[${n}]`}
                                            required={true}
                                            model={'Terminals'}
                                            preload={true}
                                            where={{ status: "in_stock", in_stock: { inq: ["store", "store_used"] } }}
                                            defaultValue={row}
                                        />

                                    </div>

                                ))}

                            </FieldList>
                        </Col>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [showImport, props]);

}

export default TerminalsBatchTransferForm;
