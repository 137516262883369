import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { Alert, FieldDate, FieldRadio, FieldTextArea, FieldUpload, Link } from '@dex/bubl-dash';
import { disablePastDates } from '../../../functions/sharedValidation';

const ServiceStageReviewFailed: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_review_failed");

    const isDateDisabled = useCallback((date) => {

        return disablePastDates(date);

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"review_failed"}
            heading={"Review Failed Service Attempt"}
            editable={false}
            editCaps={["stage_review_failedServices"]}
            showForm={true}
            showMeta={true}
            passed={false}
            showSave={form.values.next_step === "retry"}
            columnA={[
                {
                    key: "last_attempt.onandby",
                    label: "Last Attempt Submitted",
                    format: "onandby"
                },
                {
                    key: "last_attempt.date",
                    label: "Last Attempt Date",
                    format: "date"
                },
            ]}
            columnB={[
                {
                    key: "last_attempt.technician",
                    label: "Last Attempt Technician",
                },
                {
                    key: "last_attempt.attachments",
                    label: "Last Attempt Supporting Document",
                    format: "file"
                },
            ]}
            columnC={[
                {
                    key: "last_attempt.remarks",
                    label: "Last Attempt Reason Failed",
                },

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Next Step"}
                                name='next_step'
                                required={true}
                                options={[
                                    { label: "Retry", value: "retry" },
                                    { label: "Cancel", value: "cancel" },
                                ]}
                            />

                        </Col>

                        {form.values.next_step === "retry" &&
                            <>
                                <Col col={{ xs: 24, md: 6 }}>

                                    <FieldDate
                                        form={form}
                                        key={form.defaults.appointment_date}
                                        name={"appointment_date"}
                                        label={"Appointment Date"}
                                        defaultValue={form.defaults.appointment_date}
                                        disabledDate={isDateDisabled}
                                        required={true}
                                    />

                                </Col>

                            </>
                        }

                        {form.values.next_step === "cancel" &&
                            <>
                                <Col col={{ xs: 24, md: 8 }}>
                                    <Alert
                                        type='warning'
                                        message={"Cancel Service"}
                                        description={<><Link path={"/services/" + data.id + "/cancel"}>Click here</Link> to cancel service.</>}
                                    />
                                </Col>
                            </>
                        }

                    </Row>

                    {form.values.next_step === "retry" &&
                        <>
                            <Row gutter={8} edge={true}>
                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldTextArea
                                        form={form}
                                        label={"Remarks"}
                                        name='retry_remarks'
                                        required={false}
                                    />

                                </Col>
                                <Col col={{ xs: 24, md: 8 }}>
                                    <FieldUpload
                                        form={form}
                                        label={"Supporting Documents"}
                                        name="retry_documents"
                                        defaultValue={form.defaults.retry_documents}
                                        folder={"uploads"}
                                        limit={3}
                                    />
                                </Col>

                            </Row>

                        </>

                    }
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStageReviewFailed;
