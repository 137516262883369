import React, { useMemo } from 'react';
import MerchantStageVerifyDocuments from './MerchantStageVerifyDocuments';
import MerchantStageSubmitDocuments from './MerchantStageSubmitDocuments';
import MerchantStageAcquirersApproval from './MerchantStageAcquirersApproval';
import MerchantStagePosFormReceived from './MerchantStagePosFormReceived';
import MerchantStageBeginInstallation from './MerchantStageBeginInstallation';
import MerchantStageAddOns from './MerchantStageAddOns';
import MerchantStagePrepareInvoice from './MerchantStagePrepareInvoice';
import MerchantStageVerifyPayments from './MerchantStageVerifyPayments';
import MerchantStageDepositPayment from './MerchantStageDepositPayment';
import MerchantsApi from '../MerchantsApi';

const MerchantStages: React.FC<any> = (props: any) => {

    const { data } = props;

    const isLinked = data.prepare_invoice === "linked";
    const isZero = !isLinked && data.proforma_invoice?.total === 0;
    const invoice = isLinked ? data.invoice : data.proforma_invoice;

    let skipInvoice = (data.business_model === "rent-to-own" || data.business_model === "rental" || data.business_model === "maintenance");

    let verifyPayments = false;

    if (data.prepare_invoice) {
        if (isZero) verifyPayments = true;
        else if (invoice && invoice.status === "paid") verifyPayments = true;
        else if (data.invoice && invoice && invoice.status === "partially_paid") verifyPayments = true;
    }

    return (

        <>

            <MerchantStageVerifyDocuments
                data={data}
            />

            <MerchantStageSubmitDocuments
                data={data}
            />

            {!skipInvoice &&
                <>

                    <MerchantStageAddOns
                        data={data}
                        apiService={MerchantsApi}
                    />

                    {(data.select_addons === 'yes' || data.select_addons === 'skip') &&
                        <MerchantStagePrepareInvoice
                            data={data}
                            apiService={MerchantsApi}
                        />
                    }

                    {(data.prepare_invoice === "yes") && (

                        <MerchantStageDepositPayment
                            data={data}
                            apiService={MerchantsApi}
                        />

                    )}

                    {verifyPayments && (
                        <MerchantStageVerifyPayments
                            data={data}
                            apiService={MerchantsApi}
                        />
                    )}


                </>
            }

            {data.documents_submitted === 'yes' &&
                <MerchantStageAcquirersApproval
                    data={data}
                />
            }

            {(data.merchant_approved === 'yes') &&
                <MerchantStagePosFormReceived
                    data={data}
                />
            }

            {(data.pos_form_received === 'yes' && (data.payment_verified === "yes" || skipInvoice)) && (

                <MerchantStageBeginInstallation
                    data={data}
                    apiService={MerchantsApi}
                />

            )}

        </>

    );

}

export default MerchantStages;
